.header-card {
  margin: 0;
  font-weight: 400;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  flex: 0 0 auto;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-conform-window {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 20px 24px;

  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
}

.confirm-modal-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 64px);
  max-width: 600px;
  min-width: 300px;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  margin: 32px;
  overflow-y: auto;
}